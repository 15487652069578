<template>
  <div class="wb--container">
    <topbar drawer :title="navbarTitle"></topbar>
    <div class="mt-6" v-if="!loading">
      <v-card class="mx-auto" max-width="600">
        <v-card-text>
          <ValidationObserver ref="observer">
            <ValidationProvider v-slot="{ errors }" rules="required" name="title">
              <v-text-field
                v-model="form.title"
                class="mb-2"
                name="title"
                label="Countdown Titel"
                placeholder="Countdown to Invictus Launch Week"
                :error-messages="errors"
                outlined
                required
                counter
                maxlength="40"
              ></v-text-field>
            </ValidationProvider>
              <v-text-field
                v-model="form.description"
                name="description"
                class="mb-2"
                label="Countdown Beschreibung"
                placeholder="Invictus Launch Week"
                hint="Beschreibung des Events (zusatz für die Kalenderlinks)"
                outlined
              ></v-text-field>

            <div>
              <datetime ref="untilTime" v-model="form.until" format="dd LLL yyyy HH:mm:ss" type="datetime" input-class="form-control d-none" :min-datetime="minDatetime" class="theme-orange"></datetime>
              <v-text-field
                :value="untilInputValue"
                label="Start des Events"
                outlined
                required
                readonly
                @click="$refs.untilTime.isOpen = true"
              >
              </v-text-field>
            </div>
            <v-text-field
              v-model="form.duration"
              type="number"
              name="duration"
              class="mb-2"
              label="Eventdauer"
              hint="Dauer des Events in Stunden, 0 = allDay (zusatz für die Kalenderlinks)"
              outlined
            ></v-text-field>
            <div>
              <datetime ref="dtstartTime" v-model="form.dtstart" format="dd LLL yyyy HH:mm:ss" type="datetime" input-class="form-control d-none" :min-datetime="minDatetime" :max-datetime="maxDateTime" class="theme-orange"></datetime>
              <v-text-field
                :value="dtstartInputValue"
                label="Countdown Start"
                outlined
                required
                readonly
                @click="$refs.dtstartTime.isOpen = true"
              >
              </v-text-field>
            </div>
            <v-checkbox
              v-model="form.active"
              label="Page Countdown Aktivieren"
            ></v-checkbox>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="preview = !preview">Vorschau</v-btn>
          <v-btn color="blue darken-1" text @click="onSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
      <div :hidden="!preview" class="countdown-preview mt-6">
        <div class="wb-container">
          <div class="wb-countdown__wrapper">
            <div class="wb-countdown__countdown">
              <p class="wb-countdown__baseline">{{form.title}}</p>
              <ul class="wb-countdown__units-wrapper">
                <li class="wb-countdown__unit wb-countdown__unit--days">
                  <div class="wb-countdown__digit">00</div>
                  <div class="wb-countdown__label">Days</div>
                </li>
                <li class="wb-countdown__unit wb-countdown__unit--hours">
                  <div class="wb-countdown__digit">00</div>
                  <div class="wb-countdown__label">Hours</div>
                </li>
                <li class="wb-countdown__unit wb-countdown__unit--minutes">
                  <div class="wb-countdown__digit">00</div>
                  <div class="wb-countdown__label">Minutes</div>
                </li>
              </ul>
            </div>
            <button class="wb-countdown__cta">
              <a :href="googleEvent" target="_blank" class="calendar-options__button calendar-options__button--google">
                Google
              </a>
              <div class="wb-countdown__cta-label">Add to Your Calendar</div>
              <a :href="otherEvent" class="calendar-options__button calendar-options__button--others">
                Others
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ValidationProvider, ValidationObserver} from 'vee-validate';
  import Topbar from '../components/Topbar';
  import {Datetime} from 'vue-datetime';
  import addMinutes from 'date-fns/addMinutes';
  import subMinutes from 'date-fns/subMinutes'
  import DateTime from 'luxon/src/datetime.js'
  import { google, ics } from "calendar-link";

  export default {
    name: 'Countdown',

    components: {
      Topbar,
      ValidationObserver,
      ValidationProvider,
      Datetime
    },
    data() {
      return {
        navbarTitle: 'Website Countdown',
        preview: false,
        countdown: null,
        form: {
          title: '',
          description: '',
          duration: 1,
          dtstart: new Date().toISOString(),
          until: addMinutes(new Date(), 10).toISOString(),
          active: false
        },
        googleEvent: '',
        otherEvent: ''
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      dtstartInputValue() {
        return DateTime.fromISO(this.form.dtstart).setLocale("de").toFormat("dd MMMM yyyy 'um' HH:mm");
      },
      untilInputValue() {
        return DateTime.fromISO(this.form.until).setLocale("de").toFormat("dd MMMM yyyy 'um' HH:mm");
      },
      minDatetime() {
        return new Date().toISOString();
      },
      maxDateTime() {
        return subMinutes(new Date(this.form.until), 10).toISOString();
      }
    },

    watch: {
      'form.dtstart': function() {
        this.generateEventLinks();
      },
      'form.until': function() {
        this.setCountdown();
        this.generateEventLinks();
      },
    },

    created() {
      this.generateEventLinks();
    },

    methods: {
      onSave() {

      },
      generateEventLinks() {
        const event = {
          title: this.form.title,
          description: this.form.description,
          start: this.form.until,
          duration: [parseInt(this.form.duration), "hour"],
          allDay: !this.form.duration ? true : false
        };
        if (!parseInt(this.form.duration)) {
          delete event.duration;
        }
        this.googleEvent = google(event);
        this.otherEvent = ics(event);
      },
      async setCountdown() {
        if (this.countdown) {
          clearInterval(this.countdown);
        }
        const self = this;
        const daysUnit = document.querySelector('.wb-countdown__unit--days .wb-countdown__digit');// span element that displays the amount of days
        const hoursUnit = document.querySelector('.wb-countdown__unit--hours .wb-countdown__digit');// span element that displays the amount of hours
        const minutesUnit = document.querySelector('.wb-countdown__unit--minutes .wb-countdown__digit');// span element that displays the amount of minutes
        // const secondsUnit = document.querySelector('.seconds');// span element that displays the amount of seconds

        const startDate = new Date(this.form.until).getTime(); // initial date and time the countdown clock started from (Year, Month, Day, Hours, Minutes, Seconds,)
        timer(startDate);
        // timer function takes in a date parameter in milliseconds
        function timer(date) {
          // countdown holds the entire timer functionality
          self.countdown = setInterval(()=>{
            const now = Date.now(); // current date and time
            const differenceInTime = date - now; // distance between current time and future time of event
            // checks timer to see if the distance is zero and if zero
            if (differenceInTime < 0) {
              clearInterval(self.countdown); // clear timer
              daysUnit.textContent = '00';
              hoursUnit.textContent = '00';
              minutesUnit.textContent = '00';
              return;
            }
            timeLeft(differenceInTime);// each iteration of setInterval send updated distance to timeLeft function
          }, 1000);// every 1 second
          const now = Date.now();
          timeLeft(startDate - now);// each iteration of setInterval send updated distance to timeLeft function
        }
        // timeLeft function takes a time as a parameter in milliseconds and displays it in Days, Hours, Minutes, and Seconds
        function timeLeft(time) {
          const days = Math.floor(time / (1000 * 60 * 60 * 24));// milliseconds into days
          const hours = Math.floor(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));// milliseconds into hours
          const minutes = Math.floor(time % (1000 * 60 * 60) / (1000 * 60));// milliseconds into minutes
          // const seconds = Math.floor(time % (1000 * 60) / 1000);// milliseconds into seconds
          // conditional added to each portion of the time that will be displayed adds a zero to the front of numbers < 10
          const displayDays = `${days < 10 ? '' : ''}${days}`;// days string that will be displayed
          const displayHours = `${hours < 10 ? '' : ''}${hours}`;// hours string that will be displayed
          const displayMinutes = `${minutes < 10 ? '' : ''}${minutes}`;// minutes string that will be displayed
          // const displaySeconds = `${seconds < 10 ? '0' : ''}${seconds}`;// seconds string that will be displayed

          // displays the time strings on the page individually
          daysUnit.textContent = displayDays;
          hoursUnit.textContent = displayHours;
          minutesUnit.textContent = displayMinutes;
          // secondsUnit.textContent = displaySeconds;
        }
      }
    }
  };
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
</style>

<style lang="scss">
  .wb--container {
    background: #011e3e;
    height: 100%;
  }
  .wb-countdown__countdown {
    position: relative;
    width: 100%;
  }
  .wb-countdown__baseline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<style lang="scss">
  @import '../assets/scss/landingpage.scss';
</style>
